/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.home-headlines {
    width: 80%;
    height: 300px;
    border-radius: 21px 21px 0 0;
}
