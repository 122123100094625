svg[data-testid="AddIcon"] {
    color: black;
    background-color: lightgreen;
    border-radius: 25px;
}

.leaflet-container {
    height: 50vh;
}

label[for=floatingInput] {
    z-index: 0;
}